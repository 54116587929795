import React from 'react'

const PrevArrow = (props) => {
    return (
        <div style={{ width: 40, height: 40, position: 'absolute', left: '2%', top: '45%', zIndex: 4 }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props} style={{ backgroundColor: 'white', borderRadius: '50%' }}>
                <path fill='#081f91' d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256 256-114.6 256-256S397.4 0 256 0zm128 288H205.3l49.38 49.38c12.5 12.5 12.5 32.75 0 45.25s-32.75 12.5-45.25 0L105.4 278.6c-8-7.9-9.4-17.7-9.4-22.6 0-4.883 1.391-14.66 9.398-22.65l103.1-103.1c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L205.3 224H384c17.69 0 32 14.33 32 32s-14.3 32-32 32z" />
            </svg>
        </div>
    )
}

export default PrevArrow

