import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import bpBrand from "../../../../Assets/img/BI-BP-2.png";
import Modal from "../../../../Global-Components/Modal";

import styles from "./styles.module.scss";

const Main = () => {

  const [modal, setModal] = useState(false);
  const history = useHistory();

  console.log(modal);

  const handleTrade = () => {
    history.push('/marketplace')
  }

  return (
    <>
      <div className={styles.content1}>
        <div className={styles.battle} id="homeImage">
          <img src={bpBrand} alt="bp-brand" className={styles.bpBrand} />
          <div className={styles.buttons}>
            <a
              href="https://play.google.com/store/apps/details?id=com.nway.battlepalooza&hl=es_AR&gl=US"
              target={"_blank"}
              rel="noreferrer"
            >
              <button className={styles.button}>
                <svg
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_2174_1818)">
                    <path
                      d="M3.8591 7.78839C3.46281 7.78839 3.12657 7.9326 2.85018 8.221C2.57378 8.50941 2.43555 8.8555 2.43555 9.25942V15.4613C2.43555 15.8753 2.57374 16.2259 2.85018 16.5143C3.12657 16.8028 3.46281 16.947 3.8591 16.947C4.25509 16.947 4.58929 16.8028 4.8614 16.5143C5.13297 16.2259 5.26913 15.8753 5.26913 15.4613V9.25942C5.26913 8.85545 5.13093 8.50941 4.8545 8.221C4.57806 7.9326 4.24619 7.78839 3.8591 7.78839Z"
                      fill="white"
                    />
                    <path
                      d="M15.3035 2.22096L16.2849 0.331851C16.3493 0.206784 16.3264 0.110879 16.216 0.0434418C16.0961 -0.0147087 16.004 0.0147571 15.9396 0.129756L14.9445 2.03397C14.0688 1.62999 13.1427 1.42764 12.1661 1.42764C11.1893 1.42764 10.2631 1.63004 9.38781 2.03397L8.39266 0.129756C8.32799 0.0147571 8.23587 -0.0144483 8.11627 0.0434418C8.00556 0.111183 7.98265 0.206784 8.04731 0.331851L9.02874 2.22096C8.03359 2.75052 7.24101 3.48803 6.6513 4.4351C6.06158 5.3827 5.7666 6.41821 5.7666 7.54346H18.5517C18.5517 6.41847 18.2567 5.38291 17.6671 4.4351C17.0773 3.48803 16.2893 2.75052 15.3035 2.22096ZM9.6296 4.9687C9.52347 5.07971 9.39671 5.13495 9.24937 5.13495C9.10173 5.13495 8.97755 5.07971 8.87624 4.9687C8.77493 4.85821 8.72428 4.72646 8.72428 4.57219C8.72428 4.41844 8.77493 4.28643 8.87624 4.17568C8.97755 4.0652 9.10202 4.00995 9.24937 4.00995C9.39671 4.00995 9.52347 4.0652 9.6296 4.17568C9.73548 4.28669 9.78867 4.41844 9.78867 4.57219C9.78838 4.7262 9.73523 4.85821 9.6296 4.9687ZM15.4555 4.9687C15.3539 5.07971 15.2295 5.13495 15.0824 5.13495C14.9347 5.13495 14.808 5.07971 14.7021 4.9687C14.596 4.85821 14.543 4.72646 14.543 4.57219C14.543 4.41844 14.596 4.28643 14.7021 4.17568C14.808 4.0652 14.9347 4.00995 15.0824 4.00995C15.2297 4.00995 15.3539 4.0652 15.4555 4.17568C15.5569 4.28669 15.6074 4.41844 15.6074 4.57219C15.6074 4.7262 15.5568 4.85821 15.4555 4.9687Z"
                      fill="white"
                    />
                    <path
                      d="M5.82227 17.668C5.82227 18.1108 5.96961 18.4855 6.26434 18.7931C6.55932 19.1006 6.91848 19.2544 7.34246 19.2544H8.36535L8.37937 22.5288C8.37937 22.9422 8.51757 23.2933 8.79396 23.5818C9.07035 23.8702 9.40226 24.0145 9.78911 24.0145C10.1852 24.0145 10.5216 23.8702 10.798 23.5818C11.0745 23.2933 11.2127 22.9423 11.2127 22.5288V19.2547H13.12V22.5288C13.12 22.9422 13.2582 23.2933 13.5346 23.5818C13.8111 23.8702 14.1472 24.0145 14.5435 24.0145C14.9396 24.0145 15.276 23.8702 15.5525 23.5818C15.8289 23.2933 15.967 22.9423 15.967 22.5288V19.2547H17.0037C17.4183 19.2547 17.7729 19.1009 18.0681 18.7933C18.3628 18.4858 18.5103 18.111 18.5103 17.6683V8.06218H5.82227V17.668Z"
                      fill="white"
                    />
                    <path
                      d="M20.4733 7.78839C20.0861 7.78839 19.7545 7.93047 19.4781 8.21384C19.2017 8.49774 19.0635 8.84647 19.0635 9.25942V15.4613C19.0635 15.8753 19.2016 16.2259 19.4781 16.5143C19.7545 16.8028 20.0864 16.947 20.4733 16.947C20.8693 16.947 21.2057 16.8028 21.4822 16.5143C21.7586 16.2259 21.8968 15.8753 21.8968 15.4613V9.25942C21.8968 8.84643 21.7586 8.49774 21.4822 8.21384C21.2057 7.93047 20.8693 7.78839 20.4733 7.78839Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2174_1818">
                      <rect
                        width="23"
                        height="24"
                        fill="white"
                        transform="translate(0.666016 0.0144043)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                ANDROID
              </button>
            </a>

            <a
              href="https://apps.apple.com/us/app/battlepalooza-battle-royale/id1536697211"
              target={"_blank"}
              rel="noreferrer"
            >
              <button className={styles.button}>
                <svg
                  viewBox="0 0 22 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.596 0.0609131C14.3006 0.144944 12.7865 0.922582 11.9042 1.93522C11.0993 2.85386 10.4372 4.21829 10.6954 5.54426C12.1107 5.58556 13.5732 4.78941 14.4205 3.75968C15.2133 2.80116 15.8131 1.44528 15.596 0.0609131Z"
                    fill="white"
                  />
                  <path
                    d="M20.7173 7.85733C19.4736 6.39464 17.7256 5.54579 16.0749 5.54579C13.8957 5.54579 12.9739 6.52424 11.4598 6.52424C9.89866 6.52424 8.71261 5.54864 6.82801 5.54864C4.97681 5.54864 3.00564 6.6097 1.75582 8.42418C-0.00122792 10.9793 0.299459 15.7832 3.14687 19.875C4.16587 21.3392 5.52655 22.9856 7.30637 22.9998C8.89029 23.0141 9.33677 22.047 11.4826 22.037C13.6284 22.0257 14.0354 23.0126 15.6163 22.997C17.3976 22.9842 18.8327 21.1597 19.8517 19.6956C20.5821 18.6459 20.854 18.1175 21.4204 16.9326C17.3004 15.4613 16.6398 9.96663 20.7173 7.85733Z"
                    fill="white"
                  />
                </svg>
                IOS
              </button>
            </a>

            {/* <a
              href="https://play.google.com/store/apps/details?id=com.nway.battlepalooza&hl=es_AR&gl=US"
              target={"_blank"}
              rel="noreferrer"
            > */}
            <button className={styles.button} onClick={() => setModal(true)}>
              <svg
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_2174_1837)">
                  <path
                    d="M0.666016 15.523L8.04345 16.5398V9.4977H0.666016V15.523Z"
                    fill="white"
                  />
                  <path
                    d="M0.666016 8.58895H8.04345V1.46036L0.666016 2.47713V8.58895Z"
                    fill="white"
                  />
                  <path
                    d="M8.85449 16.648L18.6658 18V9.4977V9.49759H8.85449V16.648Z"
                    fill="white"
                  />
                  <path
                    d="M8.85449 1.35215V8.58895H18.6657V0L8.85449 1.35215Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2174_1837">
                    <rect
                      width="18"
                      height="18"
                      fill="white"
                      transform="translate(0.666016)"
                    />
                  </clipPath>
                </defs>
              </svg>
              PC
            </button>
            {/* </a> */}
          </div>
          <button className={styles.tradeNow} onClick={handleTrade}>TRADE NOW</button>
        </div>
      </div>
      {
        modal && (
          <div className={styles.modalContainer}>
            <Modal
              style={{ height: "250px" }}
              title="COMING SOON..."
              handleClose={() => setModal(false)}
            />
          </div>
        )
      }
    </>
  );
};

export default Main;
